// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


// https://ionicframework.com/docs/theming/themes#stepped-color-generator


/** Ionic CSS Variables **/

:root {
  
  --ion-background-color:#023020;
  --ion-background-color-rgb: 2,48,32;  
  --ion-foreground-color: #b8dc70;
  --ion-color-base: #9ACD32;
  --ion-text-color: #f5f5f5;
  --ion-text-color-rgb: 245,245,245;

  --ion-color-step-50: #232426;
  --ion-color-step-100: #2e2f31;
  --ion-color-step-150: #393a3b;
  --ion-color-step-200: #444446;
  --ion-color-step-250: #4f4f51;
  --ion-color-step-300: #595a5c;
  --ion-color-step-350: #646567;
  --ion-color-step-400: #6f7071;
  --ion-color-step-450: #7a7b7c;
  --ion-color-step-500: #858687;
  --ion-color-step-550: #909092;
  --ion-color-step-600: #9b9b9d;
  --ion-color-step-650: #a6a6a7;
  --ion-color-step-700: #b1b1b2;
  --ion-color-step-750: #bcbcbd;
  --ion-color-step-800: #c6c7c8;
  --ion-color-step-850: #d1d1d3;
  --ion-color-step-900: #dcdcdd;
  --ion-color-step-950: #e7e7e8;

  --ion-color-primary: #9ACD32;
  --ion-color-primary-rgb: 154,205,50;
  --ion-color-primary-contrast: whitesmoke;
  --ion-color-primary-contrast-rgb: 245,245,245;
  --ion-color-primary-shade: #B5E300;
  --ion-color-primary-tint: #D1FF1A;

  --ion-color-secondary: #a9d02f;
  --ion-color-secondary-rgb: 169,208,47;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #95b729;
  --ion-color-secondary-tint: #b2d544;

  --ion-color-tertiary: #6c9023;
  --ion-color-tertiary-rgb: 108,144,35;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5f7f1f;
  --ion-color-tertiary-tint: #7b9b39;

  --ion-color-success: #2dd55b;
	--ion-color-success-rgb: 45,213,91;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28bb50;
	--ion-color-success-tint: #42d96b;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #c5000f;
	--ion-color-danger-rgb: 197,0,15;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ad000d;
	--ion-color-danger-tint: #cb1a27;

	--ion-color-light: #f6f8fc;
	--ion-color-light-rgb: 246,248,252;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d8dade;
	--ion-color-light-tint: #f7f9fc;

	--ion-color-medium: #5f5f5f;
	--ion-color-medium-rgb: 95,95,95;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #545454;
	--ion-color-medium-tint: #6f6f6f;

	--ion-color-dark: #2f2f2f;
	--ion-color-dark-rgb: 47,47,47;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #292929;
	--ion-color-dark-tint: #444444;



  ion-button{
    text-transform: none;
  }

  ion-searchbar{
    --background: #f4f5f8; 
    --color: var(--ion-color-dark);
  }

  ion-segment{ 
    --ion-toolbar-color-unchecked: #a8a7a7;
  }

  ion-toolbar{
    --background:var(--ion-background-color)
  }

  app-read-more p small a.text-primary {
    //  --ion-text-color: var(--ion-color-primary, red);
    color: yellowgreen;
    text-align: right;
      }

  share-buttons {
    --sb-color:var(--ion-color-step-300)
  }

  ion-card{
    --background: #03432d;
  }

}

@media only screen and (min-width: 900px) {
  ion-modal{
    --width: 35%;
    }
}
