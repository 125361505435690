/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

 .toast {
	--background: whitesmoke;
    --color: grey;
    --button-color: grey;	
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

// @import '@ionic/angular/css/palettes/dark.system.css';
// @import '@ionic/angular/css/palettes/dark.always.css';

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* CSS required for Swiper */
/* SWiper css */
// import Swiper and modules styles
@import "swiper/css/bundle";

swiper-container {
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #3880ff);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
    --swiper-navigation-size: 40px;
    --swiper-navigation-color: whitesmoke;
  }
  
  swiper-slide {
    display: flex;
    position: relative;
  
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  
    width: 100%;
    height: 100%;
  
    font-size: 18px;
  
    text-align: center;
    box-sizing: border-box;
  }
  
  swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  /* Optional default theme */
  /* @import "ngx-sharebuttons/themes/default"; */

  


